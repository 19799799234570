import {css, html, LitElement, TemplateResult} from 'lit';
import {property, state} from 'lit/decorators.js';
import {classMap} from "lit/directives/class-map.js";

export class GameCarousel extends LitElement {
    static override styles = css`

        :host .overflow-hidden {
            overflow: hidden;
        }

        :host .carousel {
            position: relative;
        }

        :host .content::-webkit-scrollbar {
            height: 0;
            background: transparent;
        }

        :host .content {
            overflow-x: auto;
            overflow-y: auto;
            scrollbar-width: none;
        }

        :host .arrow {
            position: absolute;
            width: 72px;
            height: 72px;
            cursor: pointer;
            z-index: 2;
            padding: 0;
            border: none;
            background: none;
            display: none;
            top: 50%;
            transform: translateY(-50%);
        }

        :host .arrow,
        :host .arrow > div {
            transition-duration: .2s;
            transition-timing-function: ease-in-out;    
        }
        
        @media only screen and (min-width: 575px) {
            :host .arrow {
                display: block;
            }
        }

        :host .arrow--right {
            right: 0;
            transition-property: right;
        }

        :host .arrow--left {
            left: 0;
            transition-property: left;
        }
        
        :host .arrow > div {
            height: 32px;
            width: 32px;
            margin: 0 auto;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-position: center;
            transition-property: box-shadow;
        }

        :host .arrow--right > div {
            background-image: url('data:image/svg+xml,<svg width="36" height="36" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 39C30.4934 39 39 30.4934 39 20C39 9.50659 30.4934 1 20 1C9.50659 1 1 9.50659 1 20C1 30.4934 9.50659 39 20 39Z" fill="black" stroke="url(%23paint0_linear)" stroke-width="2"/><path d="M15 27L18 30L28 20L18 10L15 13L22 20L15 27Z" fill="white"/><defs><linearGradient id="paint0_linear" x1="2" y1="20.1277" x2="38" y2="20.1277" gradientUnits="userSpaceOnUse"><stop stop-color="%238405D9"/><stop offset="1" stop-color="%23F20574"/></linearGradient></defs></svg>');
        }

        :host .arrow--left > div {
            background-image: url('data:image/svg+xml,<svg width="36" height="36" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M20 39C30.4934 39 39 30.4934 39 20C39 9.50659 30.4934 1 20 1C9.50659 1 1 9.50659 1 20C1 30.4934 9.50659 39 20 39Z" fill="black" stroke="url(%23paint0_linear)" stroke-width="2"/><path d="M25 13L22 10L12 20L22 30L25 27L18 20L25 13Z" fill="white"/><defs><linearGradient id="paint0_linear" x1="2" y1="20.1277" x2="38" y2="20.1277" gradientUnits="userSpaceOnUse"><stop stop-color="%238405D9"/><stop offset="1" stop-color="%23F20574"/></linearGradient></defs></svg>');
        }

        @media only screen and (min-width: 1024px) {
            :host .carousel--animated .arrow--left {
                left: -36px;
            }

            :host .carousel--animated .arrow--right {
                right: -36px;
            }

            :host .carousel--animated .arrow > div {
                box-shadow: var(--gff-carousel-arrow-shadow, none);
            }
        }


        @media only screen and (min-width: 1150px) {
            :host .carousel--animated .arrow--left {
                left: -72px;
            }

            :host .carousel--animated .arrow--right {
                right: -72px;
            }
        }

        //todo: remove this once REL-162385 is deployed
        :host .items {
            display: grid;
            grid-auto-flow: column;
            justify-content: start;
            gap: 12px;
        }

        __THEME_CSS_CODE__
    `;

    @state()
    showLeftArrow = false;

    @state()
    showRightArrow = false;

    /**
     *
     */
    @property({type: Boolean, attribute: 'animated-handles'})
    animatedHandles = false;

    timer: NodeJS.Timeout | undefined;

    __scroll(direction: string) {
        const content = this.shadowRoot?.querySelector<HTMLElement>('.content');
        if (content?.offsetWidth) {
            const scrollAmount =
                ((direction === 'left' ? -content.offsetWidth : content.offsetWidth) / 3) * 2;

            content?.scroll({
                left: content.scrollLeft + scrollAmount,
                behavior: 'smooth',
            });
        }
    }

    __scrollLeft() {
        this.__scroll('left');
    }

    __scrollRight() {
        this.__scroll('right');
    }

    override connectedCallback(): void {
        super.connectedCallback();
        this.bindEvents();
    }

    override disconnectedCallback(): void {
        super.disconnectedCallback();
        this.removeEvents();
    }

    override render() {
        return html`
            <div class="carousel ${classMap({'carousel--animated': this.animatedHandles })}">
                <div class="content" @scroll="${this.handleScroll}">
                    ${this.getScrollBody()}
                </div>
                ${this.getScrollLeftButton()}
                ${this.getScrollRightButton()}
            </div>
        `;
    }

    private bindEvents() {
        this.asyncContentCheck();
        addEventListener('message', this.postMessageHandler.bind(this));
    }

    private removeEvents() {
        clearInterval(this.timer);
        removeEventListener('message', this.postMessageHandler.bind(this));
    }

    /**
     * loop every 100ms until the slots root element has child nodes. this is far from a scalable solution, but, it will
     * have to do for now.
     * @private
     */
    private asyncContentCheck(): void {
        this.timer = setInterval(() => {
            const node = this.renderRoot?.querySelector('slot')?.assignedElements()[0] as HTMLElement;

            if (node && node.childElementCount > 0) {
                this.updateArrowVisibility();
                clearInterval(this.timer);
            }
        }, 100);
    }

    private postMessageHandler(event: MessageEvent) {
        if (!top?.location.origin.includes(event.origin)) {
            return;
        }

        const eventData = event.data;
        if (eventData.action !== 'carousel-content-changed' || eventData.id !== this.id) {
            return;
        }

        this.updateArrowVisibility();
    }

    private getScrollBody(): TemplateResult {
        return html`
            <slot @slotchange=${this.updateArrowVisibility}></slot>
        `;
    }

    private getScrollLeftButton(): TemplateResult | undefined {
        if (!this.showLeftArrow)
            return;

        return html`
            <button @click="${this.__scrollLeft}" class="arrow arrow--left" aria-label="Move Games">
                <div></div>
            </button>`
    }

    private getScrollRightButton(): TemplateResult | undefined {
        if (!this.showRightArrow)
            return;

        return html`
            <button @click="${this.__scrollRight}" class="arrow arrow--right" aria-label="Move Games">
                <div></div>
            </button>`
    }

    private updateArrowVisibility(): void {
        const container:HTMLElement = this.renderRoot?.querySelector('.content') as HTMLElement;

        if (container) {
            const maxScroll = container.scrollWidth - container.offsetWidth;
            const scrollAmount = container.scrollLeft;

            this.showLeftArrow = scrollAmount > 0;
            this.showRightArrow = (scrollAmount < maxScroll - 1);
        }
    }

    private handleScroll(): void {
        this.updateArrowVisibility();
    }

    override firstUpdated(): void {
        this.updateArrowVisibility();
    }
}

if (!customElements.get('game-carousel')) {
    customElements.define('game-carousel', GameCarousel);
}
